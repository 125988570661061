import React from 'react'
import Cookies from 'universal-cookie';
import {Redirect} from 'react-router-dom';
import useApi from '../../utils/useApi.js'

const AuthContext = React.createContext()

const cookies = new Cookies();

class AuthProvider extends React.Component {
    constructor() {
        super()
        this.state = {
            token: cookies.get('token') || null,
            loading: false,
            resetSuccess: false,
            error : null,
            resetToken: null
        }

        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.forgotPassword = this.forgotPassword.bind(this)
        this.resetPassword = this.resetPassword.bind(this)

    }

    login(institution, username, password) {

        this.setState({loading: true})

        useApi('api/login', {
            method: 'POST',
            data: {
                "institution": institution || "",
                "username": username || "",
                "password": password || ""
            }
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            if (json.status === "ERR"){

                var error = json.errors;

                if (json.errors.msg.includes("Unknown database")){
                    error = {"msg": "Institution not found"}
                }

                this.setState({
                    error: error,
                    loading: false
                })
            }
            else {
                cookies.set('token', json.data.token, { path: '/', domain: '.artificialsensing.com'});
                this.setState({
                    token: json.data.token,
                    loading: false
                })
            }
        })
    }

    logout() {
        cookies.remove('token', { path: '/', domain: '.artificialsensing.com'})
        this.setState({
            token: null,
            resetSuccess: false,
            resetToken: null,
            error: null,
            loading: false
        })
    }

    forgotPassword(institution, email) {
        this.setState({
            loading: true,
            resetToken: null
        })

        useApi('api/user/forgot-password', {
            method: 'POST',
            data: {
                "institution": institution || "",
                "email": email || ""
            }
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            if (json.status === "ERR"){
                this.setState({
                    error: json.errors,
                    loading: false,
                    resetToken: null,
                    resetSuccess: false,
                })
            }
            else {
                console.log(json.data.token)
                this.setState({
                    error: null,
                    resetToken: json.data.email_job,
                    loading: false,
                    resetSuccess: false,
                })
            }
        })
    }

    resetPassword(data) {

        this.setState({loading: true})

        return useApi('api/user/reset-password', {
            method: 'POST',
            data: {
                "token": data["token"],
                "institution": data["institution"],
                "new_password": data["newPassword"],
                "confirm_password" : data["confirmPassword"]
            }
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            if (json.status === "ERR"){
                return this.setState({
                    error: json.errors,
                    loading: false,
                    resetSuccess: false
                })
            }
            else {
                console.log(json)
                this.setState({
                    error: null,
                    resetToken: null,
                    resetSuccess: true,
                    loading: false
                })

                return (<Redirect to="/"/>)
            }
        })
    }

    render() {
        return (
            <AuthContext.Provider value={{
                token: this.state.token,
                loading: this.state.loading,
                resetToken: this.state.resetToken,
                resetPassword: this.resetPassword,
                error: this.state.error,
                resetSuccess: this.state.resetSuccess,
                login: this.login,
                logout: this.logout,
                forgotPassword: this.forgotPassword
            }}>
            {this.props.children}
            </AuthContext.Provider>
        )
    }
}

const AuthConsumer = AuthContext.Consumer

export {
    AuthProvider,
    AuthConsumer
}
